<template>
  <div :class="[`unload-list__item-status ${colorType}`]">
    <r-text
      type="caption"
      :color-type="colorType"
    >
      {{ statusTitle }}
    </r-text>
  </div>
</template>

<script>
import { statusList } from '../../configs'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      statusList
    }
  },
  computed: {
    status() {
      return this.statusList[this.item.status]
    },
    colorType() {
      return this.status?.color || 'default'
    },
    statusTitle() {
      return this.status?.title || 'без статуса'
    }
  }
}
</script>

<style lang="scss">
.unload-list__item-status {
  padding-left: 12px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 6px;
    width: 6px;
    border: 1px solid #fff;
    border-radius: 50%;
    background-color: var(--icons_low_contrast);
  }

  &.accent-primary {
    &:before {
      background-color: var(--accent_primary);
    }
  }

  &.accent-warning {
    &:before {
      background-color: var(--accent_warning);
    }
  }

  &.accent-error {
    &:before {
      background-color: var(--accent_error);
    }
  }

  &.accent-success {
    &:before {
      background-color: var(--accent_success);
    }
  }
}
</style>
